@tailwind base;
@tailwind components;
@tailwind utilities;

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Hide scrollbar for Chrome, Safari and Edge */
::-webkit-scrollbar {
  display: none;
}

/* For general elements like divs, sections, etc. */
.no-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  overflow: -moz-scrollbars-none; /* Legacy Firefox */
  overflow-y: scroll; /* Enable vertical scrolling */
}
